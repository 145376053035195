import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import AccountsController from "./accounts_controller"
import AccountActivitiesFilterController from "./account_activities_filter_controller"
import BookingController from "./booking_controller"
import BookingAutoTracesController from "./booking_auto_traces_controller"
import BookingRoomBlockController from "./booking_room_block_controller"
import ClientFiltersController from "./client_filters_controller"
import DashboardAgendaController from "./dashboard_agenda_controller"
import EditClientController from "./edit_client_controller"
import FunctionDiaryController from "./function_diary_controller"
import AutomationDashboardController from "./automation_dashboard_controller"
import LeadsDashboardController from "./leads_dashboard_controller"
import LeadImportsController from "./lead_imports_controller"
import OnEventDashboardController from "./on_event_dashboard_controller"
import LoginHelperController from "./login_helper_controller"
import PmsController from "./pms_controller"
import Select2Applicator from "./select2_applicator"
import SertifiController from "./sertifi_controller"
import SignaturesController from "./signatures_controller"
import UserFormController from "./user_form_controller"
import FormsBookmarkableController from "./forms/bookmarkable_controller"
import FormsCheckboxController from "./forms/checkbox_controller"
import FormsDatepickerController from "./forms/datepicker_controller"
import FormsMultiselectController from "./forms/multiselect_controller"
import FormsOptionalFieldsController from "./forms/optional_fields_controller"
import FormsSubmitController from "./forms/submit_controller"
import MenuItemsController from "./menu_items_controller"
import BookingAgentsLoaderController from "./booking_agents_loader_controller"

Stimulus.register("accounts", AccountsController)
Stimulus.register("account-activities-filter", AccountActivitiesFilterController)
Stimulus.register("booking", BookingController)
Stimulus.register("booking-room-block", BookingRoomBlockController)
Stimulus.register("booking-auto-traces", BookingAutoTracesController)
Stimulus.register("dashboard-agenda", DashboardAgendaController)
Stimulus.register("edit-client", EditClientController)
Stimulus.register("function-diary", FunctionDiaryController)
Stimulus.register("automation-dashboard", AutomationDashboardController)
Stimulus.register("leads-dashboard", LeadsDashboardController)
Stimulus.register("select2-applicator", Select2Applicator)
Stimulus.register("sertifi", SertifiController)
Stimulus.register("on-event-dashboard", OnEventDashboardController)
Stimulus.register("client-filters", ClientFiltersController)
Stimulus.register("signatures", SignaturesController)
Stimulus.register("lead-imports", LeadImportsController)
Stimulus.register("pms", PmsController)
Stimulus.register("user-form", UserFormController)
Stimulus.register("forms--multiselect", FormsMultiselectController)
Stimulus.register("forms--bookmarkable", FormsBookmarkableController)
Stimulus.register("forms--optional-fields", FormsOptionalFieldsController)
Stimulus.register("forms--submit", FormsSubmitController)
Stimulus.register("forms--datepicker", FormsDatepickerController)
Stimulus.register("forms--checkbox", FormsCheckboxController)
Stimulus.register("login-helper", LoginHelperController)
Stimulus.register("menu-items", MenuItemsController)
Stimulus.register("booking-agents-loader", BookingAgentsLoaderController)